import { useCallback, useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import Bar from "./Bar";
import { Text } from "./../components/Translate";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/Homepage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import setBodyColor from "../components/bodycolor";
import { createBrowserHistory } from 'history';

const Homepage = () => {

  const history = createBrowserHistory();
  const navigate = useNavigate();
  const position = '/home';
  const [Results, setResults] = useState([])
  const [settlement ,setSettlement] = useState('')
  const [status,setStatus]= useState('')
  


  const onButtonContainerClick = useCallback(() => {
    localStorage.removeItem("settlement-id");
    const headers = { 'Authorization': `Bearer ${localStorage.getItem('access')}` };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/claims`, { headers })
        .then(response => response.json())
        .then((data) =>{
          console.log(data)           
        })
        const access = localStorage.getItem('access')
        const refresh = localStorage.getItem('refresh')
        const expires = localStorage.getItem('access-timer')
        localStorage.clear()
        localStorage.setItem('access',access)
        localStorage.setItem('access-timer',expires)
        localStorage.setItem('refresh',refresh)
    navigate("/statement-intro");
  }, [navigate]);

  useEffect(() => {
    



    setBodyColor({color: "#eeefef"})
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${localStorage.getItem('access')}` },
  };
    
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/claims`, requestOptions)
   .then((response) =>{
    return response.json();  
  }).then((data) =>{ 
    console.log(data) 
    setResults(data)
    if (data.length>0){
      console.log(data[data.length-1].status)
      setStatus(data[data.length-1].status)
      }
    if(data.length != 0) {
      document.getElementById('previous').style.display = 'block';
  } else {
      document.getElementById('previous').style.display = 'none';
  } })
  .catch(error => {
    navigate('/')})
    
}, []);

  return (
    <div className="wrapperpage">
      <div class="h-100 d-flex align-items-center justify-content-center">
      <div id="wrapper">
        <Bar headerName='Homepage'/>
      <div className="button80">
        <div className="new-statement-parent">
          <div className="new-statement">
            <img className="vector-icon89" alt="" src="/vector.svg" />
          </div>
          <div className="parent57">
            <b className="b62"><Text>startnewstatement</Text></b>
            <div className="div261">
              <Text>detailsofpeople</Text>
            </div>
          </div>
        </div>
        <div className="group-parent">
          <img className="frame-child85" alt="" src="/group-798.svg" />
          <div className="parent58">
            <b className="b63"><Text>startnewstatement</Text></b>
            <div className="div262">
              <Text>detailsofpeople</Text>
            </div>
          </div>
        </div>
        <button className="button81" onClick={onButtonContainerClick} disabled={ Results !== null && Results.some(item => item.status === 'PENDING') }>
          <Text>newstatement</Text>
        </button>
      </div>
      <div className="div251">
        <div className="parent56">
          <div className="div252" id='previous'>
         <b class="b62" style={{height:'0px'}}> <Text>previousstatements</Text></b> 
          </div>
          {
       Results && Results.length>0 && Results.map((item)=><div className="previous-statements-card" key={item.id} style={{display:'inline'}}>
     
       <div className="div253" style={{color:'#46bedc',fontWeight:'600',width:'90%',cursor:'pointer',float:'left',maxWidth:'90%'}}
       onClick={() => {
        if(item.status=='PENDING'){
        setSettlement(item.id)
        localStorage.setItem('settlement-id', item.id)
        navigate('/statement-intro4') }}}>
       <div className="div253" style={{color:'#46bedc',fontWeight:'600',width:'100%'}}>
         
          {(item.status=='COMPLETE')?  <Text>completedcapital</Text> : <Text>pendingcapital</Text>
      }
         
      <br/><br/> 
           </div>
       <div className="previous-statement-info">
         <img className="driver-1-icon" alt="" src="/driver-1.svg" />
         <div className="frame-parent128">
           <div className="wrapper75">
             <div className="div252" style={{float:'right'}}>{item.accidentDateTime.substring(0, 10)}</div>
           </div>
           <div className="div255">{item.location?.address}</div>
        
          
          



           
         </div>
       </div>
       </div>
       {(item.status=='COMPLETE')?<p></p>:
       <div id='div' style={{display:"inline"}}> 
       <FontAwesomeIcon icon={faTrash} style={{color:'#ea6d6d',float:'right',cursor:'pointer'}}
              onClick={() => {
             if(item.status=='PENDING'){
             const Options = {
              mode: "cors",
              method: 'DELETE',
              headers: { 'Authorization': `Bearer ${localStorage.getItem('access')}`,  "Content-Type": "application/json" },
          };
            
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/claims/${item.id}`, Options)
          .then(res => res.text())
          .then(setResults(Results.filter((data) => data.id !== item.id )))
        }else{
          console.log('you cant delete this item')
        }
        const access = localStorage.getItem('access')
    const refresh = localStorage.getItem('refresh')
    const expires = localStorage.getItem('access-timer')
    localStorage.clear()
    localStorage.setItem('access',access)
    localStorage.setItem('access-timer',expires)
    localStorage.setItem('refresh',refresh)
           }}/></div>}
       
     </div>)
     }

        </div>
      </div>
      </div>
    </div>
    </div>
  );
};

export default Homepage;