import { useCallback, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BarTwo from "./Bar-two";
import "../css/Summary.css";
import "../css/Summary1.css";
import ModalInfo from "../components/Modal";
import { Text } from "./../components/Translate";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import chevron from '../assets/insideicon.svg'

const Summary = () => {
    const navigate = useNavigate();
    const [Accnumber,setAccnumber] = useState('');
    const [modalSave, setModalSave] = useState(false);
    const [modalSent, setModalSent] = useState(false);
    const [witness, setWitness] = useState('');
    const [injured, setInjured] = useState('');
    const [sketch, setSketch] = useState('');
    const [preport, setPreport] = useState('');
    const [accphotos, setAccphotos] = useState([]);
    const [accdesc, setAccdesc] = useState([]);




    const SentForm = () => {
        setModalSave(true);
        const Options = {
            mode: "cors",
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access')}`,  "Content-Type": "application/json" },


            body: JSON.stringify({
                type: "ACCIDENT",
                category: "MUTUAL_SETTLEMENT",
                accidentDateTime: localStorage.getItem("accident_datetime"),
                status: "COMPLETE", // can be PENDING, IN_PROGRESS, COMPLETED
                location: {
                address: localStorage.getItem("center"),
                lat:  localStorage.getItem("lat"),
                long: localStorage.getItem("lng")
                },
                drivers: [
                    {
                    owner: localStorage.getItem("Odigos_A_oximatos"),
                    vehicleRef: "A",
                    lastName:  localStorage.getItem("lastname_A_driver"),
                    firstName:  localStorage.getItem("firstname_A_driver"),
                    phone:  localStorage.getItem("phone_A_odigou"),
                    email:  localStorage.getItem("email_A_odigou"),
                    dateOfBirth:  localStorage.getItem("im_gennisis_A_odigou"),
                    address:  localStorage.getItem("address_A_odigou"),
                    postalCode:  localStorage.getItem("tk_A_odigou"),
                    country:  localStorage.getItem("country_A_odigou"),
                    drivingLicense: {
                    number: localStorage.getItem("ar_diplomatos_A_odigou"),
                    expiresAt: localStorage.getItem("isxus_diplomatos_A_odigou"),
                    vehicleCategories: [localStorage.getItem("category_diplomatos_A_odigou")]
                    }
                    },
                    {
                    owner: localStorage.getItem("Odigos_B_oximatos"),
                    vehicleRef: "B",
                    lastName:  localStorage.getItem("eponimo_B_odigou"),
                    firstName:  localStorage.getItem("Onoma_B_odigou"),
                    phone:  localStorage.getItem("tilefono_B_odigou"),
                    email:  localStorage.getItem("email_B_odigou"),
                    dateOfBirth:  localStorage.getItem("im_gennisis_B_odigou"),
                    address:  localStorage.getItem("diefthinsi_B_odigou"),
                    postalCode:  localStorage.getItem("tk_B_odigou"),
                    country:  localStorage.getItem("xora_B_odigou"),
                    drivingLicense: {
                    number: localStorage.getItem("ar_diplomatos_B_odigou"),
                    expiresAt: localStorage.getItem("isxus_diplomatos_B_odigou"),
                    vehicleCategories: [localStorage.getItem("katigoria_diplomatos_B_odigou")],
                    },
                    driverBName:localStorage.getItem("Onoma_B_odigou_2"),
                    driverBSurname:localStorage.getItem("eponimo_B_odigou_2"),
                    driverBNumber:localStorage.getItem("tilefono_B_odigou_2"),
                    driverBEmail:localStorage.getItem("email_B_odigou_2"),
                    driverBCountry:localStorage.getItem("xora_B_odigou_2"),
                    driverBPostalCode:localStorage.getItem("tk_B_odigou_2"),
                    driverBDateOfBirth:localStorage.getItem("im_gennisis_B_odigou_2"),
                    driverBAddress:localStorage.getItem("diefthinsi_B_odigou_2"),
                    drivingLicenseB: {
                    number:localStorage.getItem("ar_diplomatos_B_odigou_2"),
                    expiresAt: localStorage.getItem("isxus_diplomatos_B_odigou_2"),
                    vehicleCategories: [localStorage.getItem("katigoria_diplomatos_B_odigou_2")],
                   }
                    }
                ],  
                vehicles: [
                    {
                    ref: "A",
                    licensePlate: localStorage.getItem("pinakida_A_oximatos"),
                    manufacturer: localStorage.getItem("manufacturer_A_oximatos"),
                    model: localStorage.getItem("marka_A_oximatos"),
                    year: localStorage.getItem("year_A_oximatos"),
                    color: localStorage.getItem("color_A_oximatos"),
                    policy: {
                    insurance: '',
                    number: '',
                    greenCardNumber: ''
                    }
                    },
                    {
                        ref: "B",
                        licensePlate: localStorage.getItem("Ar_kikloforias_B_oximatos"),
                        manufacturer: localStorage.getItem("montelo"),
                        model: localStorage.getItem("marka"),
                        year: '',
                        color: localStorage.getItem("xroma"),
                        policy: {
                        insurance: localStorage.getItem("asfalistiki_B_odigou"),
                        number: localStorage.getItem("ar_diplomatos_B_odigou"),
                        greenCardNumber: localStorage.getItem("ar_prasinis_B_odigou")
                        }
                    }
                    ], 
                    representation: {
                        mapStaticUrl: localStorage.getItem("sxedio_atuximatos"),
                        lat:localStorage.getItem("lat"),
                        lng:localStorage.getItem("lng")
                    },
                    conditions: {
                        A : {
                        parked: localStorage.getItem("parked_A"),
                        starting_open_door: localStorage.getItem("starting_open_door_A"),
                        for_parking: localStorage.getItem("for_parking_A"),
                        exit_parking: localStorage.getItem("exit_parking_A"),
                        enter_parking: localStorage.getItem("enter_parking_A"),
                        enter_circular_path: localStorage.getItem("enter_circular_path_A"),
                        move_circular_path: localStorage.getItem("move_circular_path_A"),
                        rear_collision_same_lane: localStorage.getItem("rear_collision_same_lane_A"),
                        moving_same_direction_different_lane: localStorage.getItem("moving_same_direction_different_lane_A"),
                        lane_change: localStorage.getItem("lane_change_A"),
                        overtaking: localStorage.getItem("overtaking_A"),
                        turn_right: localStorage.getItem("turn_right_A"),
                        turn_left: localStorage.getItem("turn_left_A"),
                        reverse: localStorage.getItem("reverse_A"),
                        opposite_traffic_direction: localStorage.getItem("opposite_traffic_direction_A"),
                        right_on_intersection: localStorage.getItem("right_on_intersection_A"),
                        priority_sign_violation: localStorage.getItem("priority_sign_violation_A")
                        },
                        B : {
                        parked: localStorage.getItem("parked_B"),
                        starting_open_door: localStorage.getItem("starting_open_door_B"),
                        for_parking: localStorage.getItem("for_parking_B"),
                        exit_parking: localStorage.getItem("exit_parking_B"),
                        enter_parking: localStorage.getItem("enter_parking_B"),
                        enter_circular_path: localStorage.getItem("enter_circular_path_B"),
                        move_circular_path: localStorage.getItem("move_circular_path_B"),
                        rear_collision_same_lane: localStorage.getItem("rear_collision_same_lane_B"),
                        moving_same_direction_different_lane: localStorage.getItem("moving_same_direction_different_lane_B"),
                        lane_change: localStorage.getItem("lane_change_B"),
                        overtaking: localStorage.getItem("overtaking_B"),
                        turn_right: localStorage.getItem("turn_right_B"),
                        turn_left: localStorage.getItem("turn_left_B"),
                        reverse: localStorage.getItem("reverse_B"),
                        opposite_traffic_direction: localStorage.getItem("opposite_traffic_direction_B"),
                        right_on_intersection: localStorage.getItem("right_on_intersection_B"),
                        priority_sign_violation: localStorage.getItem("priority_sign_violation_B")
                        }
                        },
                        description:{
                            accident: localStorage.getItem("perigrafi"),
                            accident_audio:localStorage.getItem("perigrafi_audio"),
                            damage:localStorage.getItem("perigrafi_fthorwn"),
                            damage_audio:localStorage.getItem("perigrafi_fthorwn_audio")
                        },
                        injuries: [localStorage.getItem("travmaties")],
                        witnesses: [localStorage.getItem("martires")],
                        media: [localStorage.getItem("photo_astunomias")]

                        
                })
        };
           const requestOptions = {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${localStorage.getItem('access')}` },
};
  
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/claims`, requestOptions)
 .then((response) =>{
  return response.json();  
}).then((data) =>{ 
  if (data.length>0){
  console.log(data[data.length-1].id) 
  localStorage.setItem("settlement-id",data[data.length-1].id);
 
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/claims/${localStorage.getItem('settlement-id')}`, Options)
          .then(data => {

            console.log(data);
            
            }).catch((error) => {
                console.log("Error");
              });
        }})
     
    };
    useEffect(() => {
        if(localStorage.getItem('sxedio_atuximatos')){
            setSketch(localStorage.getItem('sxedio_atuximatos'))
        }
        if(localStorage.getItem('photo_astunomias')){
            const d= JSON.parse(localStorage.getItem('photo_astunomias'))
            setPreport(d[0].fileimage)
        }
        if(localStorage.getItem('photo_atiximatos')){
            setAccphotos(JSON.parse(localStorage.getItem('photo_atiximatos')))
        }
        if(localStorage.getItem('photo_fthorwn')){
            setAccdesc(JSON.parse(localStorage.getItem('photo_fthorwn')))
        }
        
        if ( JSON.parse(localStorage.getItem("martires"))!==null){
            setWitness(JSON.parse(localStorage.getItem("martires")))
        }
        if ( JSON.parse(localStorage.getItem("travmaties"))!==null){
            setInjured(JSON.parse(localStorage.getItem("travmaties")))
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access')}` },
        }
          
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/claims`, requestOptions)
         .then((response) =>{
          return response.json();  
        }).then((data) =>{  console.log(data[data.length-1].id)
            setAccnumber(data[data.length-1].id)
              }, []);
            
      }, []);
      

     


    const SaveForm = () => {
        setModalSent(true);
        console.log(6);
    };
    useEffect(() => {
        console.log(localStorage.getItem("pinakida_A_oximatos"))
    }, []);
    const routeChange1 = () =>{ 
        navigate('/1st-driver');
      }
      const routeChange2 = () =>{ 
        navigate('/1st-driver7');
      }
      const routeChange3 = () =>{ 
        navigate('/injured');
      }
      const routeChange4 = () =>{ 
        navigate('/accident19');
      }
      const routeChange5 = () =>{ 
        navigate('/accident1');
      }
      const routeChange6 = () =>{ 
        navigate('/witness');
      }
      const routeChange7 = () =>{ 
        navigate('/2nd-driver11');
      }
      const routeChange8 = () =>{ 
        navigate('/2nd-driver9');
      }
      const routeChange9 = () =>{ 
        navigate('/accident16');
      }
      const routeChange10 = () =>{ 
        navigate('/accident9');
      }
    
    
    return (
        <div className="summary184">
            <div className="wrapperpage">
                <div id="wrapper">
                    <BarTwo headerName={<Text>agreedstatement</Text>} />
                    <div className="parent31">
                                            {(Accnumber!=='')?<div className="div192"><Text>accnumber</Text><Text>&nbsp;{Accnumber}</Text></div>:<p></p>}
                                        </div>
                                        <br/>
                    <div className="other-car-group">
                        <div className="other-car2">
                            <Accordion className="infopersonalexpand13" style={{ padding: "0", width: "100%", boxShadow: "none", borderRadius: "10px" }}>
                                <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>
                                        <div className="parent31">
                                            <div className="div192"><Text>vehicleb</Text></div>
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="frame-parent156">
                                            <div className="statementcategoryinfo">
                                                <div className="summary1">
                                                    <div className="qwerty">{<Text>vehicle</Text>}</div>
                                                    <div className="summary-inner">
                                                        <img className="frame-item" alt="" src="/frame-2441.svg"  onClick={routeChange7}/>
                                                    </div>
                                                </div>
                                                <div className="summary-parent">
                                                    <div className="summary2">
                                                        <div className="title"><Text>licenseplate</Text>: &nbsp;</div>
                                                        <div className="information">{localStorage.getItem("Ar_kikloforias_B_oximatos")}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>brand</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("marka")} </div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>model</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("montelo")} </div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>colour</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("xroma")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {localStorage.getItem("Odigos_B_oximatos") == 'false' ?<div className="statementcategoryinfo">
                                                <div className="summary1">
                                                    <div className="qwerty"><Text>driver</Text></div>
                                                    <div className="summary-inner">
                                                        <img className="frame-item" alt="" src="/frame-2441.svg"  onClick={routeChange8}/>
                                                    </div>
                                                </div>
                                                <div className="frame-parent">
                                                    <div className="summary-parent">
                                                        <div className="summary2">
                                                            <div className="title"><Text>name</Text>:&nbsp;</div>
                                                            <div className="information">{localStorage.getItem("eponimo_B_odigou_2")} {localStorage.getItem("Onoma_B_odigou_2")}</div>
                                                        </div>
                                                        <div className="summary2">
                                                            <div className="title"><Text>dateofbirth</Text>:&nbsp;</div>
                                                            <div className="information">{localStorage.getItem("im_gennisis_B_odigou_2")}</div>
                                                        </div>
                                                        <div className="summary2">
                                                            <div className="title"><Text>phonenumber</Text>:&nbsp;</div>
                                                            <div className="information">{localStorage.getItem("tilefono_B_odigou_2")}</div>
                                                        </div>
                                                    </div>
                                                    <div className="summary-parent">
                                                        <div className="summary2">
                                                            <div className="title"><Text>email</Text>:&nbsp;</div>
                                                            <div className="information8">{localStorage.getItem("email_B_odigou_2")}</div>
                                                        </div>
                                                        <div className="summary2">
                                                            <div className="title"><Text>address</Text>:&nbsp;</div>
                                                            <div className="information" >{localStorage.getItem("diefthinsi_B_odigou_2")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>:<div/>}
                                            <div className="statementcategoryinfo">
                                                <div className="summary1">
                                                    <div className="qwerty"><Text>insurancedetails</Text></div>
                                                    <div className="summary-inner">
                                                        <img className="frame-item" alt="" src="/frame-2441.svg" onClick={routeChange8}/>
                                                    </div>
                                                </div>
                                                <div className="summary-parent">
                                                    <div className="summary2">
                                                        <div className="title"><Text>nameofinsurancecompany</Text>:&nbsp;</div>
                                                        <div className="information12" style={{color:'#242A30'}}>{localStorage.getItem("asfalistiki_B_odigou")}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>contractno</Text>: &nbsp;</div>
                                                        <div className="information">{localStorage.getItem("ar_simvolaiou_B_odigou")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="statementcategoryinfo">
                                                <div className="summary1">
                                                    <div className="qwerty"><Text>driverlicensedetails</Text></div>
                                                    <div className="summary-inner">
                                                        <img className="frame-item" alt="" src="/frame-2441.svg" onClick={routeChange8}/>
                                                    </div>
                                                </div>
                                                <div className="summary-parent">
                                                    <div className="summary2">
                                                        <div className="title"><Text>driverlicenseno</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("ar_diplomatos_B_odigou")}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>category</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("katigoria_diplomatos_B_odigou")}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>validthrough</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("isxus_diplomatos_B_odigou")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="statementcategoryinfo">
                                                <div className="summary1">
                                                    <div className="qwerty"><Text>policyholder</Text></div>
                                                    <div className="summary-inner">
                                                        <img className="frame-item" alt="" src="/frame-2441.svg" onClick={routeChange8}/>
                                                    </div>
                                                </div>
                                                <div className="frame-parent">
                                                    <div className="summary-parent">
                                                        <div className="summary2">
                                                            <div className="title"><Text>name</Text>:&nbsp;</div>
                                                            <div className="information">{`${localStorage.getItem("eponimo_B_odigou")} ${localStorage.getItem("Onoma_B_odigou")}`}</div>
                                                        </div>
                                                        <div className="summary2">
                                                            <div className="title"><Text>dateofbirth</Text>:&nbsp;</div>
                                                            <div className="information">{localStorage.getItem("im_gennisis_B_odigou")}</div>
                                                        </div>
                                                        <div className="summary2">
                                                            <div className="title"><Text>phonenumber</Text>:</div>
                                                            <div className="information">{localStorage.getItem("tilefono_B_odigou")}</div>
                                                        </div>
                                                    </div>
                                                    <div className="summary-parent">
                                                        <div className="summary2">
                                                            <div className="title"><Text>email</Text>:&nbsp;</div>
                                                            <div className="information">{localStorage.getItem("email_B_odigou")}</div>
                                                        </div>
                                                        <div className="summary2">
                                                            <div className="title"><Text>address</Text>:&nbsp;</div>
                                                            <div className="information">{localStorage.getItem("diefthinsi_B_odigou")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className="infopersonalexpand13" style={{ padding: "0", width: "100%", boxShadow: "none", borderRadius: "10px" }}>
                                <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>
                                        <div className="parent31">
                                            <div className="div192"><Text>accident</Text></div>
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="frame-parent156">
                                            <div className="frame-parent">
                                                <div className="statementcategoryinfo8">
                                                    <div className="summary1" >
                                                        <div className="qwerty"><Text>datetime</Text></div>
                                                        <div className="summary-inner">
                                                            <img className="frame-item" alt="" src="/frame-2441.svg" onClick={routeChange4} />
                                                        </div>
                                                    </div>
                                                    <div className="summary-parent">
                                                        <div className="summary2">
                                                            <div className="title"><Text>date</Text>:&nbsp;</div>
                                                            {localStorage.getItem('accident_datetime')?<div className="information"> {localStorage.getItem('accident_datetime').slice(0,-6)}</div>:<p/>}
                                                        </div>
                                                        <div className="summary2">
                                                            <div className="title"><Text>time</Text>:&nbsp;</div>
                                                            {localStorage.getItem('accident_datetime')? <div className="information">{localStorage.getItem('accident_datetime').slice(11,18)}</div>:<p/>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="statementcategoryinfo" style={{marginTop:'30px'}}>
                                                    <div className="summary1">
                                                        <div className="qwerty"><Text>accidentlocation</Text></div>
                                                        <div className="summary-inner">
                                                            <img className="frame-item" alt="" src="/frame-2441.svg" onClick={routeChange5}/>
                                                        </div>
                                                    </div>
                                                    <div className="statementcategoryinfo-inner">
                                                        <div className="summary-wrapper">
                                                            <div className="summary58">
                                                                <div className="title"><Text>accidentlocation</Text>:</div>
                                                                <div className="information">{localStorage.getItem('center')}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="statementcategoryinfo10" style={{marginTop:'20px'}}>
                                                    <div className="summary61">
                                                        <div className="qwerty"><Text>accidentdescription</Text></div>
                                                        <div className="summary-inner">
                                                            <img className="frame-item" alt="" src="/frame-2441.svg" onClick={routeChange5}/>
                                                        </div>
                                                    </div>
                                                    <div className="summary62">
                                                            <img className="screenshot-2022-12-08-at-609" alt="" src={sketch} />

                                                    </div>
                                             {localStorage.getItem('perigrafi')?<div className="statementcategoryinfo-inner">
                                                        <div className="summary-wrapper">
                                                            <div className="summary58">
                                                                <div className="title"><Text>accidentdescription</Text>:</div>
                                                                <div className="information">{localStorage.getItem('perigrafi')}</div>
                                                            </div>
                                                        </div>
                                                    </div>:<div/>}
                                                    {localStorage.getItem('perigrafi_fthorwn')? <div className="statementcategoryinfo-inner">
                                                        <div className="summary-wrapper">
                                                            <div className="summary58">
                                                          <div className="title"><Text>accidentdamage</Text>:</div>
                                                                <div className="information">{localStorage.getItem('perigrafi_fthorwn')}</div>
                                                            </div>
                                                        </div>
                                                    </div>:<div/>}
                                                </div>
                                                <div className="statementcategoryinfo" style={{marginTop:'30px'}}>
                                                    <div className="summary34">
                                                        <div className="qwerty"><Text>policereport</Text></div>
                                                        <div className="summary-inner">
                                                            <img className="frame-item" alt="" src="/frame-2441.svg"  onClick={routeChange9}/>
                                                        </div>
                                                    </div>
                                                    <div className="summary62">

                                                    </div>
                                                    <div className="summary41">
                                                    <img className="screenshot-2022-12-08-at-609" alt="" src={preport} style={{width:'150px'}}/>

                                                        <div className="photo3">
                                                            <div className="photo-inner">
                                                                <div className="icondelete-wrapper">
                                                                    <img className="icondelete" alt="" src="/icondelete3.svg" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="statementcategoryinfo">
                                                    <div className="summary34">
                                                        <div className="qwerty"><Text>accidentanddamagedescriptionlabel</Text></div>
                                                        <div className="summary-inner">
                                                            <img className="frame-item" alt="" src="/frame-2441.svg"  onClick={routeChange10}/>
                                                        </div>
                                                    </div>
                                                    <div className="summary72">
                                                        <div className="title">title:</div>
                                                        <div className="information">information</div>
                                                    </div>
                                                    <div className="frame-container">
                                                        <div className="frame-parent1">
                                                            <div className="container3">
                                                                
                                                               {localStorage.getItem('photo_atiximatos')?<div className="div2"><Text>accidentphotos</Text></div>:<div/>}
                                                                <div  style={{display:'table',width:'100%'}}>
                                                                         {accphotos.map((item, index) => (
                                                                          
                                                                                <img className="summary74" style={{width:'1450px',padding:'2px'}}
                                                                                src={item.fileimage}
                                                                                className="img-fluid"
                                                                                alt={item.title}
                                                                                />
                                                                            
                                                                            ))}
                                
                                                            </div>
                                         
                                                            </div>
                                                        </div>
                                                        <div className="frame-parent1">
                                                  
                                                            <div className="container3" style={{width:'100%'}}>
                                                            {localStorage.getItem('photo_fthorwn')?<div className="div2"><Text>damagephotos</Text></div>:<div/>}
                                                                <div style={{display:'table',width:'100%'}}>
                                                                         {accdesc.map((item, index) => (
                                                                       
                                                                                <img className="summary74" style={{padding:'2px'}}
                                                                                src={item.fileimage}
                                                                                className="img-fluid"
                                                                                alt={item.title}
                                                                                />
                                                                           
                                                                            ))}
                                                             
                                                            </div>
                                         
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className="infopersonalexpand13" style={{ padding: "0", width: "100%", boxShadow: "none", borderRadius: "10px" }}>
                                <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>
                                        <div className="parent31">
                                            <div className="div192"><Text>injuredpersons</Text></div>
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    {injured.length > 0 && (
                                <ul style={{padding:'0'}}>
                                {injured.map(injure => (
                                    <li key={injure.id}>
                                        <div className="frame-parent156">
                                            <div className="summary1">
                                                <div className="qwerty"><Text>injuredperson</Text><Text>&nbsp;{injure.id}</Text></div>
                                                <div className="summary-inner">
                                                    <img className="frame-item" alt="" src="/frame-2441.svg" onClick={routeChange3}/>
                                                </div>
                                            </div>
                                            <div className="frame-parent">
                                                <div className="summary-parent">
                                                    <div className="summary2">
                                                        <div className="title"><Text>name</Text>:&nbsp;</div>
                                                        <div className="information">{injure.eponimo} {injure.firstname}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>dateofbirth</Text>:&nbsp;</div>
                                                        <div className="information">{injure.imerominia}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>phonenumber</Text>:&nbsp;</div>
                                                        <div className="information">{injure.tilefono}</div>
                                                    </div>
                                                </div>
                                                <div className="summary-parent">
                                                    <div className="summary2">
                                                        <div className="title"><Text>email</Text>:&nbsp;</div>
                                                        <div className="information">{injure.email}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>injured</Text>&nbsp;<Text>is</Text>:&nbsp;</div>
                                                        <div className="information">{injure.travmatias === 'Οδηγός' ? <Text>driver</Text> : <Text>passenger</Text>}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>helmetseatbeltlabel</Text>:&nbsp;</div>
                                                        <div className="information">{injure.Forouse_zoni_kranos === true ? <Text>yes</Text> : <Text>no</Text>}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
            </li>
            
          ))}
        </ul>
      )}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion className="infopersonalexpand13" style={{ padding: "0", width: "100%", boxShadow: "none", borderRadius: "10px" }}>
                                <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>
                                        <div className="parent31">
                                            <div className="div192"><Text>witnesses</Text></div>
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    {witness.length > 0 && (
        <ul style={{padding:'0'}}>
          {witness.map(witnes => (
            <li key={witnes.id}>
<div className="frame-parent156">
                                            <div className="summary1">
                                                <div className="qwerty"><Text>witness</Text><Text>&nbsp;{witnes.id}</Text></div>
                                                <div className="summary-inner">
                                                    <img className="frame-item" alt="" src="/frame-2441.svg" onClick={routeChange6}/>
                                                </div>
                                            </div>
                                            <div className="frame-parent">
                                                <div className="summary-parent">
                                                    <div className="summary2">
                                                        <div className="title"><Text>name</Text>:&nbsp;</div>
                                                        <div className="information">{witnes.eponimo} {witnes.onoma}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>phonenumber</Text>:&nbsp;</div>
                                                        <div className="information">{witnes.tilefono}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"></div>
                                                        <div className="information"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
            </li>
            
          ))}
        </ul>
      )}
                                      
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion className="infopersonalexpand13" style={{ padding: "0", width: "100%", boxShadow: "none", borderRadius: "10px" }}>
                                <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>
                                        <div className="parent31">
                                            <div className="div192"><Text>myvehicle</Text></div>
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="frame-parent156">
                                            <div className="summary1">
                                                <div className="qwerty"><Text>vehicle</Text></div>
                                                <div className="summary-inner">
                                                    <img className="frame-item" alt="" src="/frame-2441.svg" onClick={routeChange1}/>
                                                </div>
                                            </div>
                                            <div className="frame-parent">
                                                <div className="summary-parent">
                                                    <div className="summary2">
                                                        <div className="title"><Text>licenseplate</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("pinakida_A_oximatos")}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>brand</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("marka_A_oximatos")}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>model</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("manufacturer_A_oximatos")} </div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>colour</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("color_A_oximatos")}</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="statementcategoryinfo">
                                                <div className="summary1">
                                                    <div className="qwerty"><Text>driver</Text></div>
                                                    <div className="summary-inner">
                                                        <img className="frame-item" alt="" src="/frame-2441.svg" onClick={routeChange2}/>
                                                    </div>
                                                </div>

                                                <div className="summary-parent">
                                                    <div className="summary2">
                                                        <div className="title"><Text>name</Text>:&nbsp;</div>
                                                        <div className="information"> {localStorage.getItem("Name_A_driver")}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>dateofbirth</Text>:&nbsp;</div>
                                                        <div className="information"> {localStorage.getItem("im_gennisis_A_odigou")}</div>
                                                    </div>
                                                    <div className="summary29">
                                                        <div className="title"><Text>phonenumber</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("phone_A_odigou")}</div>
                                                    </div>
                                                </div>
                                                <div className="summary-parent">
                                                    <div className="summary2">
                                                        <div className="title"><Text>email</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("email_A_odigou")}</div>
                                                    </div>
                                                    <div className="summary2">
                                                        <div className="title"><Text>address</Text>:&nbsp;</div>
                                                        <div className="information">{localStorage.getItem("address_A_odigou")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="statementcategoryinfo">
                                                <div className="summary1">
                                                    <div className="qwerty"><Text>driverlicensedetails</Text></div>
                                                    <div className="summary-inner">
                                                        <img className="frame-item" alt="" src="/frame-2441.svg" onClick={routeChange2}/>
                                                    </div>
                                                </div>
                                                <div className="frame-parent">
                                                    <div className="summary-parent">
                                                        <div className="summary2">
                                                            <div className="title"><Text>driverlicenseno</Text>:&nbsp;</div>
                                                            <div className="information">{localStorage.getItem("ar_diplomatos_A_odigou")}</div>
                                                        </div>
                                                        <div className="summary2">
                                                            <div className="title"><Text>category</Text>:&nbsp;</div>
                                                            <div className="information">{localStorage.getItem("category_diplomatos_A_odigou")}</div>
                                                        </div>
                                                        <div className="summary2">
                                                            <div className="title"><Text>validthrough</Text>:&nbsp;</div>
                                                            <div className="information">{localStorage.getItem("isxus_diplomatos_A_odigou")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>

                    <div className="summary-inner44">
                        <div className="button-group">
                            <div className="button53" onClick={() => SaveForm(true)}>
                                <div className="title152"><Text>save</Text></div>
                            </div>
                            <ModalInfo state="στάλθηκε" show={modalSave} onHide={() => setModalSave(false)}></ModalInfo>
                            <div className="button54" onClick={() => SentForm(true)}>
                                <div className="title152"><Text>send</Text></div>
                            </div>
                            <ModalInfo state="αποθηκεύτηκε" show={modalSent} onHide={() => setModalSent(false)}></ModalInfo>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Summary;